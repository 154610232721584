import { post } from "@/utils/request";
import { ApiList } from "@/types/common";
import {
  IPlan,
  IOrder,
  IOrderDetail,
  ProductInfo,
  IProductInstallInfo,
  IProductReleaseInfo,
  IProductInstallStatus,
} from "@/types/product";
import { AppInfo, InstallLogInfo, InstallStatus } from "@/types/app";

// 获取产品列表
export function getProductList(data?: unknown) {
  return post("/product/list", data);
}
// product/hot/search

export function getProductHotSearch() {
  return post("/product/hot/search");
}
// 获取产品列表
export function getProduct(id?: unknown) {
  return post<ProductInfo>("/product/get", { id });
}

// 获取产品计划
export function getPlanList(id: string) {
  return post<IPlan[]>("/product/plan/list", { id });
}

// 获取产品计划
export function getPlan(id: string) {
  return post<IPlan>("/product/plan/get", { id });
}
export type BuyOrderResult = {
  orderNo: string;
  pay: string;
};

// 购买产品计划
export function buyProduct(data: unknown) {
  return post<BuyOrderResult>("/order/product/create", data);
}

// 购买产品计划
export function rePayment(data: unknown) {
  return post<BuyOrderResult>("/order/product/rePayment", data);
}
// 获取支付状态
export function getOrderProduct(orderNo: string) {
  return post<IOrder>("/order/product/get", { orderNo });
}

// 申请退款
export function orderRefund(data: unknown) {
  return post("/order/product/refund/apply", data);
}
export function getOrderList(data: unknown) {
  return post<ApiList<IOrder[]>>("/order/product/list", data);
}

export function getOrder(orderNo: number | string) {
  return post<IOrderDetail>("/order/product/get", { orderNo });
}
export function getOrderById(orderId: number | string) {
  return post<IOrderDetail>("/order/product/get", { orderId });
}

export function orderRefundCancel(id: number | string) {
  return post("/order/product/refund/cancel", { id });
}

export function orderClose(id: number | string) {
  return post("/order/product/close", { id });
}

// 获取产品安装列表
export function getInstallList(data?: unknown) {
  return post<ApiList<IProductInstallInfo[]>>(
    "/order/product/install/list",
    data
  );
}

// 安装前检查
export function installCheck(data?: unknown) {
  return post<boolean | {
    result: boolean
    reason: string
    warning: string
    alerts: string | string[]
  }>(
    "/order/product/installCheck",
    data
  );
}

// 获取产品发布列表
export function getReleaseList(data?: unknown) {
  return post<ApiList<IProductReleaseInfo[]>>("/product/release", data);
}

// 安装
export function reInstall(id: number) {
  return post("/order/product/reInstall", { id });
}

// 创建的产品安装
export function productInstall(id: number) {
  return post("/product/install", { id });
}

// 购买产品安装
export function orderProductInstall(data: unknown) {
  return post("/order/product/install", data);
}

// 获取产品安装信息
export function getProductInstallInfo(id: unknown) {
  return post<AppInfo>("/product/install/info/get", { id });
}

// 设置本身产品属性
export function setProductAutoUpgrade(data: {
  id: string;
  autoUpgrade: boolean;
}) {
  return post("/order/product/autoUpgrade", data);
}


// 产品升级
export function productUpgrade ( data : { id : string , env : string }) {
  return post('/order/product/upgrade', data )
}

// 获取所有正在升级的产品
export function productUpgrading () { 
  return post('/product/app/upgrading')
}

// 查询产品安装状态
export function productInstallStatus(id: string): Promise<InstallStatus> {
  return post('/product/install/status', {id})
}
// 获取当前产品的订单列表
export function getProductOrderList(productCode: string) { 
  return post('/order/list/by/product', { productCode})
}

export function apiProductSysChangeLogDetail(data: any) {
  return post('/sysChange/productChangeDetail', data);
}
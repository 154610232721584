export default {
  "共{count}条数据": "共{count}条数据",
  "我已阅读并同意{privacy} 和 {user}": "我已阅读并同意{privacy} 和 {user}",
  "支付成功，可以到【{name}】中查看详情": "支付成功，可以到【{name}】中查看详情",
  "已勾选子服务{count}个": "已勾选子服务{count}个",
  "合计: {count}个": "合计: {count}个",
  "约{price}元/月": "约{price}元/月",
  "{price}元": "{price}元",
  "有效截止日期为{date}": "有效截止日期为{date}",
  "当前有效截止日期为{date}": "当前有效截止日期为{date}",
  "请及时进行线下支付，可以到【{name}】中查看详情": "请及时进行线下支付，可以到【{name}】中查看详情"
};
